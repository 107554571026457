import { links } from '@/config/envVars';
import { chainType, NETWORKS } from '@/config/network';

const isMainnet = chainType === 'mainnet';

import { ROUTES } from '@/types/enums';

export const USEFUL_LINKS = [
  {
    name: 'prices',
    url: '/prices',
  },
  {
    name: 'markets',
    url: '/markets',
  },
  {
    name: 'htm-booster',
    logo: 'booster.svg',
    url: '/lend',
    hiddenOn: 'menudrawer',
  },
  {
    name: 'governance',
    logo: 'governance-xs.svg',
    url: '#',
    hiddenOn: 'menudrawer',
  },

  // {
  //   name: 'safety-module',
  //   logo: 'safety-module.svg',
  //   url: '#',
  //   hiddenOn: 'menudrawer',
  // },
  {
    name: 'docs',
    url: links.docURL,
    external: true,
    hiddenOn: 'megamenu',
  },
  {
    name: 'blog',
    url: links.blogURL,
    hiddenOn: 'megamenu',
    external: true,
  },
  {
    name: 'security',
    url: 'https://docs.hatom.com/security/',
    external: true,
    hiddenOn: 'megamenu',
  },
];

export const PRODUCTS = [
  {
    title: 'Lending App',
    url: '/lend',
    active: true,
  },
  {
    title: 'Liquid Staking',
    url: ROUTES.LIQUID_APP,
    active: true,
  },
  {
    title: 'TAO Bridge',
    url: '/bridge',
  },
  {
    title: 'USH Staking',
    url: '/ush',
  },
  {
    title: 'Soul Protocol',
    url: '#',
  },
];

export const NETWORKS_LIST = [
  {
    text: 'Devnet',
    value: NETWORKS.devnet,
    url: links.devnetURL,
  },
  {
    text: 'Testnet',
    value: NETWORKS.testnet,
    url: links.testnetURL,
  },
  {
    text: 'Mainnet',
    value: NETWORKS.mainnet,
    url: links.mainnetURL,
  },
];

export const ECOSYSTEMS = [
  {
    title: 'Hatom Protocol',
    url: links.hatomUrl,
    active: true,
  },
  {
    title: 'Hatom Labs',
    url: links.hatomLabsURL,
  },
  {
    title: 'Esdt',
    url: links.esdtURL,
  },
  {
    title: 'HatomUSD',
    url: '#',
  },
  {
    title: 'Hatom Mush',
    url: '#',
  },
  {
    title: 'Syfy',
    url: '#',
  },
  {
    title: 'Hatom Treasury',
    url: '#',
  },
  {
    title: 'Soul',
    url: '#',
  },
];
