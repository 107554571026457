import classNames from 'classnames';
import Link from 'next/link';

import LanguageSwitcher from '@/components/LanguageSwitcher';
import LatestBlock from '@/components/LatestBlock';

import { links } from '@/config/envVars';
import { capitalizeFirstLetter } from '@/utils/helpers';

import Container from './components/Container';
import { DashboardFooterProps } from './helpers/types';
import useLinksData from './helpers/useLinksData';
import useSocialData from './helpers/useSocialData';

export const SOCIAL_MEDIA = useSocialData();

const DashboardFooter = ({
  className,
  inheritTheme,
  separator,
  langSwitcherProps = {},
}: DashboardFooterProps) => {
  const LINKS = useLinksData();

  return (
    <footer
      className={classNames(
        className,
        !inheritTheme && 'text-[#6C7DA6] dark:text-[#53648C]',
        'font-hass',
        'text-xs font-semibold leading-none',
        'Header container mx-auto lg:max-w-[calc(1352px)]',
        'relative flex flex-col gap-5 pb-4 md:flex-row md:justify-between md:pt-[22px]',
      )}
    >
      <div className='flex items-center justify-between md:gap-10'>
        <Link legacyBehavior href={links.hatomUrl || '#'}>
          <a>
            <img
              className='w-[24px]'
              src='https://cdn.app.hatom.com/logo.svg'
              alt=''
            />
          </a>
        </Link>
        <nav>
          <ul className='flex gap-1.5'>
            {LINKS.map((l, i) => (
              <li
                key={i}
                className='whitespace-nowrap border-current first:border-r first:pr-1.5 hover:underline'
              >
                <Container url={l.path} external={l.external}>
                  <a>{capitalizeFirstLetter(l.name)}</a>
                </Container>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {/* Separator */}
      <div
        className={classNames(
          'absolute inset-x-0 top-9 h-[1.5px] w-full bg-current md:top-0',
          inheritTheme && !separator
            ? 'text-current'
            : 'opacity-30 dark:text-[#0B2037] dark:opacity-100',
        )}
        style={{ background: separator }}
      />

      <div className='flex items-center gap-2 md:-mr-1 md:gap-[18px]'>
        <div className='md:hidden'>
          <LatestBlock darkTheme={false} transparent={false} />
        </div>
        <div
          className={classNames(
            'ml-auto border-r border-current pr-3 md:pr-[22px]',
            !inheritTheme && 'text-[#A9B7D7] dark:text-[#53648C]',
          )}
        >
          <ul className='flex items-center gap-3 md:gap-6'>
            {SOCIAL_MEDIA.map((media, i) => {
              return (
                <li key={i}>
                  <Link legacyBehavior href={media.url} passHref>
                    <a
                      target='_blank'
                      className='transform-colors cursor-pointer hover:opacity-80'
                    >
                      {media.icon}
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className={classNames(
            !inheritTheme && 'text-[#A9B7D7] dark:text-[#53648C]',
          )}
        >
          <LanguageSwitcher {...langSwitcherProps} />
        </div>
      </div>
    </footer>
  );
};

export default DashboardFooter;
